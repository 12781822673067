<template>
    <!-- <div :class="[flag? '': 'content-box']"> -->
        <!-- <div :class="[flag? '': 'container']"> -->
            <div class="content" style="padding:15px">
                 <h4 class="global_head_title head_title">添加/编辑产品服务</h4>
                 <div class="content_row flex">
                <label class="info" for="">分类名称</label>
                  <div class="content_right">
                      <a-select v-model="onType">
                        <a-select-option
                            v-for="(item, index) in typeList"
                            :key="index"
                            :value="item.id"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
               <div class="content_row flex">
                <label class="info" for="">商品名称</label>
                  <div class="content_right">
                    <a-input v-model="name" size="large" placeholder="请输入商品名称，最多二十个字" maxLength="20"/>
                </div>
            </div>
            
            <div class="content_row flex">
                <label class="info" for="">商品单价</label>
                  <div class="content_right">
                    <a-input v-model="money" size="large" placeholder="请输入商品单价" prefix="￥"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">销售数量</label>
                  <div class="content_right">
                    <a-input v-model="sales" size="large" placeholder="请输入销售数量"/>
                </div>
            </div>
             <div class="content_row flex" >
                <label class="info" for="">商品图片</label>
                  <div class="content_right">
                     <template>
                        <div class="imgItem" v-for="(item,index) in imageIdList" :key="index">
                            <a-icon class="ico" type="close-square" @click="delImg(index)"/>
                            <img :src="ajaxUrl+'/scrm/index/getFile?id='+item">
                        </div>
                         <div style="padding-top:15px;color:#999;font-size:13px">建议尺寸 <span style="color:red">750x400像素</span></div>
                        <label class="fileLabel" for="image"><a-icon type="upload" /> 上传 </label>
                        <input type="file" id="image" ref="image"  @change="imgUpload($event)">
                    </template>
                </div>
            </div>
            <div class="content_row flex" >
                <label class="info" for="">产品描述</label>
                <div class="content_right">
                     <div id="wangeditor"></div>
                    <!-- <a-textarea v-model="desc" id='textarea1' placeholder="请输入产品描述"/> -->
                </div>
            </div>
             <div class="content_row flex" >
                <label class="info" for="">状态</label>
                <div class="content_right">
                    <a-switch checked-children="启" un-checked-children="禁" :checked="status==1?true:false" @click="onChange(1)"/>
                </div>
            </div>
             <div class="content_row flex" >
                <label class="info" for="">首页推荐</label>
                <div class="content_right">
                   <a-switch checked-children="启" un-checked-children="禁" :checked="recommend==2?true:false" @click="onChange(2)"/>
                </div>
            </div>
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
            </div>
        <!-- </div> -->
    <!-- </div> -->
</template>
<script>
// import $ from "jquery";
import {isMobile,ajaxUrl,fileUpload,requestXml} from '../../../assets/js/request';
import E from 'wangeditor';
var editor;
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            type: 0,
            name:'',  //名称
            money:'', //单价
            sales:'', //销售数
            desc:'',
            onType:'',
            upUrl:ajaxUrl+'/scrm/index/UpFiles',
            id: "", 
            typeList:[],
            imageId: '',
            imageIdList: [],
            ajaxUrl:ajaxUrl,
            status:1,
            recommend:1,
       };
    },
    mounted: function(){
        editor = new E('#wangeditor')
        let that=this;
          editor.config.excludeMenus = [
            'fontSize',
            'emoticon',
            'video'
        ]
         editor.config.onchange = function (html) {
           that.desc=html;
        }
        editor.config.uploadImgServer = '/index.php/jieb/Product/uploads';
        editor.config.uploadFileName = 'file';
        // editor.config.uploadImgMaxLength = 1; // 一次最多上传 5 个图片
        editor.create()
      
       
        this.id = this.$route.query.id;
        if(this.id){
            this.getDetail();
            // console.log(this.id);
        }
        this.getType();
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },
    watch:{
        // taskId: function(newVal){ 
        //     this.id = newVal;
        // },
    },
    methods: {
        submit(){
            let url='/jieb/Product/addproduct';
            let data={status:this.status,recommend:this.recommend,'pdid':this.onType, "name": this.name,'img':this.imageIdList.join(','),'unitprice':this.money,'describe':this.desc,'salenum':this.sales}
            if(this.id){
                url='/jieb/Product/saveproduct';
                data.id=this.id;
            }
             requestXml(url,"POST",(res) => {
                console.log(res);
                this.$router.go(-1);//返回上一层
                //  if(res=='success'){
                //     this.$message.success('成功');
                //  }else{
                //     this.$message.info('失败');
                //  }
            },data)
        },
        // 状态和首页推荐
        onChange(type){
            if(type==1){
                if(this.status==1){
                    this.status=2;
                }else{
                    this.status=1;
                }
            }else if(type==2){
                if(this.recommend==1){
                    this.recommend=2;
                }else{
                    this.recommend=1;
                }
            }
        },
        delImg(index){
            this.imageIdList.splice(index, 1);
        },
        // 图片上传
        imgUpload(e){
            fileUpload(e,(res) => {
                if(this.imageIdList.indexOf(res.id)==-1){
                    this.imageIdList.push(res.id);
                     this.$refs.image.value =''
                }else{
                    this.$message.info('该图片已存在');
                }
            })
        },
        // 获取产品分类
        getType(){
             requestXml("/jieb/Product/showcategory","POST",(res) => {
                this.typeList=res.list;
            },{"form":"mycreated","page":{"pagesize":'999'}})
        },
        
        // 获取详情
        getDetail() {
            requestXml("/jieb/Product/productdetail","GET",(res) => {
               console.log(res);
               this.onType=res.pdid;
                this.name=res.name; 
                this.money=res.unitprice;  
                this.sales=res.salenum;  
                this.desc=res.describe; 
                editor.txt.html(this.desc) // 重新设置编辑器内容
                this.imageId=res.img;
                this.imageIdList=res.img.split(',');
                this.status=res.status;
                this.recommend=res.recommend;
            },{id: this.id})
        },
    }
};
</script>
<style scoped>
    .head_title{
    padding-bottom: 10px;
    }
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 0 15px;
        font-size: 14px;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
        display: block;
        width: 100px;
        margin-top: 15px;
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .ant-select-selection{
        background-color: #fff;
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
    
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
    }
        .imgItem{
            display: inline-block;
            padding-right: 10px;
            text-align: right;
        }
        .imgItem .ico{
            cursor: pointer;
        }
        .imgItem img{
            width: 150px;
            height: 80px;
            display: block;
        }
</style>
